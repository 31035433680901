<template>
  <div>
    <div class="card-title mb-3">
      {{ $t("role.planning.producer.title") }}
    </div>
    <div>
      <TB :tb_config="tb">
        <e-columns>
          <e-column
            field="id"
            headerText="ID"
            type="number"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="producer.role.name"
            :headerText="$t('models.productproducer.producer')"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            :allowEditing="true"
            editType="dropdownedit"
            :edit="producerParams"
          ></e-column>
          <e-column
            field="market_wh"
            :headerText="$t('models.mpc.market_wh')"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="address.to"
            :dataSource="whData"
            foreignKeyField="id"
          ></e-column>
          <e-column
            field="order_offset"
            :headerText="$t('models.mpc.order_offset')"
            type="number"
            clipMode="EllipsisWithTooltip"
            defaultValue="0"
          ></e-column>
          <e-column
            field="delivery_offset"
            :headerText="$t('models.mpc.delivery_offset')"
            type="number"
            clipMode="EllipsisWithTooltip"
            defaultValue="0"
          ></e-column>
          <e-column
            field="delivery_type"
            :headerText="$t('models.mpc.delivery_type')"
            type="number"
            clipMode="EllipsisWithTooltip"
            :allowEditing="true"
            editType="dropdownedit"
            foreignKeyValue="name"
            :dataSource="DeliveryType"
            foreignKeyField="id"
          ></e-column>
          <e-column
            field="delivery_method"
            :headerText="$t('models.mpc.delivery_method')"
            type="number"
            clipMode="EllipsisWithTooltip"
            :allowEditing="true"
            editType="dropdownedit"
            foreignKeyValue="name"
            :dataSource="DeliveryMode"
            foreignKeyField="id"
          ></e-column>
          <e-column
            field="producer.role.country"
            :headerText="$t('models.family_address.country')"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="producer.role.tel"
            :headerText="$t('models.family_address.phone')"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="producer.role.info_contact"
            :headerText="$t('models.logistics.connections.contacts')"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
            :disableHtmlEncode="false"
          ></e-column>
          <e-column
            field="producer.role.desc_zone"
            :headerText="$t('models.logistics.connections.serviceZone')"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
            :disableHtmlEncode="false"
          ></e-column>
          <e-column
            field="member_from"
            :headerText="$t('models.logistics.connections.activeSince')"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
        </e-columns>
      </TB>
    </div>
  </div>
</template>
<script>
import { GetDataManager, GetDataManagerNew } from "../../../ds/index";
import TB from "../../../common/TB";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { Query } from "@syncfusion/ej2-data";
let stateElem, stateObj;
export default {
  components: {
    TB,
  },
  data() {
    return {
      tb: {
        data: GetDataManager("role_marketproducer_connections", [
          this.$store.state.role.id,
        ]),
        searchOptions: {
          fields: [
            // "user.first_name",
            // "user.last_name",
            "producer.role.name",
            "id",
          ],
        },
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Normal",
        },
        toolbar: ["Add", "Edit", "Delete", "Update", "Cancel"],
        // reload_on_save:true,
      },
      producerParams: {
        create: () => {
          stateElem = document.createElement("input");
          return stateElem;
        },
        read: () => {
          if (stateObj) {
            return stateObj.value;
          }
          return stateElem.getAttribute("producer_id");
        },
        destroy: () => {
          if (stateObj) {
            stateObj.destroy();
          }
        },
        write: (args) => {
          if (args.rowData.producer.id > 0) {
            stateElem.value = args.rowData.producer.role.name;
            stateElem.setAttribute("producer_id", args.rowData.producer.id);
          } else {
            stateObj = new DropDownList({
              dataSource: GetDataManagerNew("role_marketproducer", [
                this.$store.state.role.id,
              ]),
              fields: { value: "id", text: "role.name" },
              value: args.rowData.producer.id,
              enabled: !(args.rowData.producer.id > 0),
              query: new Query().take(10),
              // .where("country_id", "equal", args.rowData.address.country)
              // .take(10),
              placeholder: "Seleziona un produttore",
              floatLabelType: "Never",
              allowFiltering: true,
              actionComplete: () => false,
            });
            stateObj.appendTo(stateElem);
          }
        },
      },
      whData: GetDataManager("role_market_planningwh", [
        this.$store.state.role.id,
      ]),
      DeliveryType: [
        { id: 1, name: "Consegna" },
        { id: 2, name: "Ritiro" },
        { id: 3, name: "Consegna diretta" },
      ],
      DeliveryMode: [
        { id: 1, name: "Per ordine" },
        { id: 2, name: "Per prodotto" },
      ],
      tsFormat: { type: "dateTime", format: "dd/MM/yyyy HH:mm:ss" },
    };
  },
};
</script>
